<template>

    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'reports' }">{{$t('label_agents_invoices')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_invoice')}}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    ref="invoice-content"
                    class="mb-0"
            >

                <div class="col-md-12 mt-1">
                    <div class="panel panel-default">

                        <div class="panel-body">
                            <div class="d-flex align-items-center justify-content-between ">

                                <h3>
                                    {{itemData? itemData.agent.userType_data.company_name : ''}}
                                </h3>


                                <h4>{{$t('label_invoice')}} #
                                    <br>
                                    <strong>{{itemData.invoice_no}}</strong>
                                </h4>

                            </div>
                            <hr>

                            <div class="d-flex  justify-content-between mt-3">

                                <div class="" style="width:50%;">
                                    <h4 class="mb-1">
                                        {{$t('label_seller')}}</h4>

                                    <p class="text font-13 ">
                                        <strong>{{$t('label_name_and_surname')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.agent.name : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_company_name')}}: </strong>
                                        <span class="">{{itemData? itemData.agent.userType_data.company_name : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_address')}}:</strong>
                                        <span class="m-l-5">
                                                            {{itemData && itemData.agent.userType_data.street ? itemData.agent.userType_data.street + ', ' : ''}}
                                                {{itemData && itemData.agent.userType_data.zip ? itemData.agent.userType_data.zip + ', ' : ''}}
                                                {{itemData && itemData.agent.userType_data.town ? itemData.agent.userType_data.town : '' }}
                                                        </span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_nip')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.agent.userType_data.NIP : ''}} </span>
                                    </p>


                                    <p v-if="itemData && itemData.agent.userType_data.KRS" class="text font-13 m-0">
                                        <strong>
                                            <!-- KRS -->{{$t('label_krs')}}: </strong>
                                        <span class="m-l-5">{{itemData.agent.userType_data.KRS}} </span>
                                    </p>


                                    <p v-if="itemData && itemData.agent.userType_data.account_number"
                                       class="text font-13 m-0">
                                        <strong>
                                            <!-- Account Number -->{{$t('label_account_number')}}: </strong>
                                        <span class="m-l-5">{{itemData.agent.userType_data.account_number}} </span>
                                    </p>


                                </div>
                                <div class="">
                                    <p><strong>
                                        <!-- Date of invoice -->{{$t('label_date_of_invoice')}}
                                        : </strong> {{itemData? formatDate(itemData.date_of_invoice,
                                        'DD.MM.YYYY','unix') : ''}}
                                    </p>
                                    <p><strong>
                                        <!-- Date of selling -->{{$t('label_date_of_selling')}}
                                        : </strong> {{itemData? formatDate(itemData.date_of_selling,
                                        'DD.MM.YYYY','unix') : ''}}
                                    </p>
                                    <p><strong>
                                        <!-- Payment Type -->{{$t('label_payment_type')}}
                                        : </strong> {{itemData.pament_type}}</p>
                                    <p><strong>
                                        <!-- Term of Payment -->{{$t('label_term_of_payment')}}
                                        : </strong> {{itemData? formatDate(itemData.term_of_payment,
                                        'DD.MM.YYYY','unix') : ''}}
                                    </p>

                                    <p><strong>
                                        <!-- Place of invoice -->{{$t('label_place_of_invoice')}}
                                        : </strong>
                                        {{itemData.place_of_invoice}}</p>
                                </div>
                            </div><!-- end col -->


                            <div class="mt-3">
                                <div class="pull-left m-t-30 removeLineSpace">
                                    <h4 class="mb-1">
                                        {{$t('label_buyer')}}</h4>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_company')}}: </strong>
                                        <span class="m-l-0">{{itemData? itemData.details.company : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_address')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.details.address : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_post_code')}}: </strong>
                                        <span class="m-l-0">{{itemData? itemData.details.post_code : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_nip')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.details.nip : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_krs')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.details.krs : ''}}</span>
                                    </p>
                                    <p class="text font-13 ">
                                        <strong>{{$t('label_account_number')}}: </strong>
                                        <span class="m-l-5">{{itemData? itemData.details.acount_number : ''}}</span>
                                    </p>
                                </div>
                            </div>

                            <!-- end row -->


                            <div class="mt-3">
                                <div class="table-responsive">
                                    <table class="table m-t-30">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                <!-- Name of service -->{{$t('label_name_of_service')}}
                                            </th>
                                            <th>
                                                <!-- Quantity -->{{$t('label_quantity')}}
                                            </th>
                                            <th>
                                                <!-- Net Price -->{{$t('label_net_price')}}
                                            </th>
                                            <th>
                                                <!-- VAT -->{{$t('label_vat')}}
                                            </th>
                                            <th>
                                                <!-- Gross Price -->{{$t('label_gross_price')}}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <template v-if="itemData && itemData.agent_invoice_payments">
                                            <tr v-for="pay,ind in itemData.agent_invoice_payments">
                                                <td>{{ind + 1}}</td>
                                                <td>{{pay.service_name}}</td>
                                                <td>1</td>
                                                <td>{{pay.net_price + ' ' + itemData.currency}}</td>
                                                <td>{{(pay.vat != 0) ? pay.vat + ' ' + itemData.currency : 'ZW'}}</td>
                                                <td>{{pay.gross_price + ' ' + itemData.currency}}</td>
                                            </tr>
                                        </template>

                                        <tr>
                                            <td></td>
                                            <td>
                                                <!-- SUM -->{{$t('label_sum')}}
                                            </td>
                                            <td></td>
                                            <td>{{summary.total_net_price.formatPrice() + ' ' + itemData.currency}}</td>
                                            <td>{{(summary.total_vat != 0) ? summary.total_vat + ' ' + itemData.currency
                                                : 'ZW'}}
                                            </td>
                                            <td>{{summary.total_gross_price.formatPrice() + ' ' + itemData.currency}}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div class=" mt-2 mb-4 d-flex justify-content-end">

                                <div>
                                    <hr>
                                    <h3 class="text-right">
                                        <!-- Sum to pay -->{{$t('label_sum_to_pay')}}
                                        : {{summary.total_gross_price.formatPrice() + ' ' + itemData.currency}}</h3>
                                </div>
                            </div>

                            <hr>

                            <div v-if="itemData && itemData.agent.userType_data.vat_payer == 'N'">
                                <div class="col-md-12">
                                    <p>{{itemData.agent.userType_data.vat_payer_txt}}</p>
                                </div>
                            </div>

                            <div class="hidden-print">
                                <div class="d-flex justify-content-end">
                                    <a @click="printInvoice()"
                                       class="btn btn-outline-primary">
                                        <feather-icon size="18" icon="PrinterIcon"></feather-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </b-card>
        </b-col>

    </div>
</template>

<script>

    import {
        BCard, BButton, BLink, BFormInput,
        BBadge, BDropdown, BDropdownItem, BCardBody, BCardHeader, BFormCheckbox, BTooltip,
        BTableSimple, BTbody, BTr, BTd, BThead, BTh,

    } from 'bootstrap-vue'


    import {A_INVOICE_PREFIX as PREFIX} from './../report/moduleHelper'

    export default {
        components: {
            BCard,
            BCardBody,
            BButton,

            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BFormInput,
            BTableSimple, BTbody, BTr, BTd, BThead, BTh

        },

        data() {
            return {
                PREFIX,

                itemData: false,
                summary: {
                    total_gross_price: 0,
                    total_net_price: 0,
                    total_vat: 0
                }
            }
        },

        created() {
            let invoice_id = this.$router.currentRoute.params.invoice_id;
            this.async('get', '/agent_invoice/' + invoice_id, {params: {}}, function (res) {

                this.itemData = res.data;
                let summary = {total_gross_price: 0, total_net_price: 0, total_vat: 0};
                for (let i = 0; i < res.data.agent_invoice_payments.length; i++) {

                    summary.total_gross_price += parseFloat(res.data.agent_invoice_payments[i].gross_price);
                    summary.total_net_price += parseFloat(res.data.agent_invoice_payments[i].net_price);
                    summary.total_vat += parseFloat(res.data.agent_invoice_payments[i].vat);
                }
                this.summary = summary;

            });
        },
        methods: {
            printInvoice() {
                let w = window.print();
            }
        },

    }
</script>

<style scoped>
    @media print {
        .main-menu {
            display: none !important;
        }
        .menu-fixed {
            display: none !important;
        }
        .menu-accordion {
            display: none !important;
        }
        .main-menu-content {
            display: none !important;
        }

        .breadcrumb {
            display: none;
        }

        .hidden-print, .btn-scroll-to-top, footer {
            display: none;
        }
    }
</style>



